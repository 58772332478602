@import url('https://fonts.googleapis.com/css?family=Raleway:200|Roboto+Slab:100,400');

// $primary: #a51616;
$primary: #1b6baf; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}

nav {
	z-index: 1000;
}
h1,h2,h3 {
	font-family: 'Raleway', sans-serif;
}
p {
	font-family: 'Roboto Slab', serif;
	font-weight: 400;
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 1em 3em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

.banner {
	background: url('../img/banner2.jpg') no-repeat;
	background-size: cover;
	height: 800px;
	padding: 200px 0;
	@media (max-width:1200px) {
		background-position: 60% 50%;
	}
	@media (max-width: 991px) {
		background-position: 70% 50%;
	}
	@media (max-width: 600px) {
		height: auto;
		padding: 50px 0;
	}
	.box {
		@media (max-width: 991px) {
			background: rgba($primary, 0.5);
			padding: 15px;
		}
	}
	p, h1 {
		color: white;
	}
	h1 {
		font-size: 40px;
	}
	p {
		font-size: 20px;
	}
}

.banner2 {
	background: url('../img/banner.jpg') no-repeat;
	height: auto;
	background-size: cover;
	padding: 100px 0;
	@media (max-width: 767px) {
		background-position: 20% 50%;
	}
	h1,p {
		color: white;
	}
	p {
		font-size: 20px;
	}
}

a.btn.btn-large {
	@include btn1;
}

.logo {
	max-width: 200px;
	@media (max-width: 767px) {
		max-width: 150px;
	}
}
.navbar-default {
	background: white;
}
.form {
	background: rgba(39,121,190,0.8);
	border-radius: 10px;
}

.bg {
	background: $secondary;
	padding: 100px 0;
	@media (max-width: 767px) {
		padding: 50px 0;
	}
	h1,p {
		color: white;
	}
	p {
		font-size: 20px;
	}
}
.banner3 {
	background: url('../img/banner3.jpg') no-repeat;
	background-size: cover;
	height: 600px;
	padding: 150px 0;
	@media (max-width: 991px) {
		height: auto;
		padding: 50px 0;
		background-position: 50% 50%;
	}
	p {
		color: white;
		font-size: 20px;
	}
	h1 {
		color: white;
	}
}

i.fa {
	color: white;
	padding: 10px 0;
}

.nav.navbar-nav {
	margin-top: 20px;
	@media (max-width: 767px) {
		margin-top: 0;
	}
}